<template>
  <div class="container margin">
    <div class="columns is-centered is-mobile">
      <div class="column is-narrow has-text-centered is-flex is-flex-direction-column">
        <figure
          class="image is-128x128 is-align-self-center"
          style="margin-inline-end: 0; padding: 0"
        >
          <img class="is-rounded" style="margin: 0 auto" :src="imageUrl" />
        </figure>
        <div class="is-align-self-flex-end">
          <form enctype="multipart/form-data" novalidate>
            <input
              id="file"
              type="file"
              multiple
              @change="filesChange"
              accept="image/*"
              class="input-file"
              style="visibility: hidden"
            />
            <label for="file" class="btn"
              ><b-icon
                class="hover-imfd is-align-self-flex-end"
                icon="camera-plus-outline"
                style="margin-right: 10vh"
              ></b-icon
            ></label>
          </form>
        </div>
        <div class="has-text-centered name-label">
          {{ `${person.first_name} ${person.last_name}` }}
        </div>
        <div class="has-text-centered position-label">{{ person.active_membership }}</div>
      </div>
    </div>

    <b-tabs position="is-centered" v-model="activeTab">
      <b-tab-item :label="$t('views.personProfile.personalInformation')" value="personalInfo">
        <div class="columns is-centered">
          <div class="column is-6 is-12-mobile" v-if="!editProfile">
            <person-card :person="person" />
          </div>
          <div class="column is-6 is-12-mobile" v-else>
            <person-edit-profile :person="person" @closeEditProfile="editProfile = false" />
          </div>
        </div>
      </b-tab-item>

      <b-tab-item :label="$t('views.personProfile.associatedDivisions')" value="divisions">
        <v-promised :promise="memberDivisions">
          <template #pending>
            <b-loading :can-cancel="false"></b-loading>
          </template>

          <template v-slot="data">
            <div class="columns is-multiline" v-if="data.length > 0">
              <div
                class="column is-4"
                v-for="memberDivision of data"
                :key="`division${memberDivision.id}`"
              >
                <division-card
                  :division="memberDivision"
                  :role="memberDivision.role_type || person.active_membership"
                />
              </div>
            </div>

            <div class="is-flex is-flex-direction-column mt-4 mb-4" v-else>
              <div class="is-align-self-center">
                <img id="no-division" src="@/assets/images/sin_divisiones.png" />
              </div>
              <div class="is-align-self-center">
                <p v-html="$t('divisionDetailBody.noSubdivisions')"></p>
              </div>
            </div>
          </template>

          <template #rejected="error">
            <v-fail :error="error" />
          </template>
        </v-promised>
      </b-tab-item>
      <b-tab-item :label="$t('views.personProfile.associatedProjects')" value="projects">
        <v-promised :promise="memberProjects">
          <template #pending>
            <b-loading :can-cancel="false"></b-loading>
          </template>

          <template v-slot="data">
            <div class="columns is-multiline" v-if="data.length > 0">
              <div
                class="column is-4"
                v-for="memberProject of data"
                :key="`project${memberProject.id}`"
              >
                <division-card :division="memberProject" :role="memberProject.role_type" />
              </div>
            </div>

            <div class="is-flex is-flex-direction-column mt-4 mb-4" v-else>
              <div class="is-align-self-center">
                <img id="no-division" src="@/assets/images/sin_proyectos.png" />
              </div>
              <div class="is-align-self-center">
                <p v-html="$t('divisionDetailBody.noProjects')"></p>
              </div>
            </div>
          </template>

          <template #rejected="error">
            <v-fail :error="error" />
          </template>
        </v-promised>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import DivisionCard from '@/components/DivisionCard.vue'
import PersonCard from '@/components/PersonCard.vue'
import PersonEditProfile from '@/components/PersonEditProfile.vue'
import { uploadAvatar } from '@/requests/people'

import { findMemberDivisions, findMemberProjects } from '@/requests/people'

const { mapState } = createNamespacedHelpers('auth')

export default {
  name: 'profile',
  components: {
    DivisionCard,
    PersonCard,
    PersonEditProfile
  },
  methods: {
    async filesChange(event) {
      const profile = await uploadAvatar(event.target.files[0])
      this.$buefy.toast.open({
        message: this.$t('views.personProfile.updateAvatar'),
        type: 'is-success',
        position: 'is-top'
      })
      this.$store.commit('auth/refreshProfile', profile)
    }
  },
  props: {
    initailTab: {
      type: String
    }
  },
  data() {
    return {
      memberDivisions: null,
      memberProjects: null,
      editProfile: false,
      editPhoto: false
    }
  },
  computed: {
    ...mapState(['person']),
    activeTab: {
      set(val) {
        const query = { ...this.$route.query }
        query.tab = val
        if (val !== 'personalInfo') {
          this.editProfile = false
        }

        this.$router.replace({ query: query })
      },
      get() {
        return this.$route.query.tab || 'personalInfo'
      }
    },
    imageUrl() {
      const url = process.env.VUE_APP_MEDIA_URL || 'http://localhost:8001/'
      return `${url.replace(/\/$/, '')}${this.person.avatar}?rnd=${new Date().getTime()}`
    }
  },
  mounted() {
    this.memberDivisions = findMemberDivisions({ userId: this.person.id })
    this.memberProjects = findMemberProjects({ userId: this.person.id })
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/stylesheets/imfd/colors.scss';

.margin {
  margin-top: 2vh;
}

.name-label {
  color: $dark;
  font-size: 21px;
  font-family: 'Roboto Slab';
}
.hover-imfd {
  :hover {
    color: $azul-imfd;
    cursor: pointer;
  }
}
</style>
