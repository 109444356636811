<template>
  <b-select :value="value" @input="setInput" expanded>
    <option v-for="country in countries" :value="country.code" :key="country.code">
      {{ country.name }}
    </option>
  </b-select>
</template>

<script>
import { fetchCountries } from '@/requests/people'

export default {
  name: 'CountrySelector',
  props: {
    value: {
      require: false
    }
  },
  data() {
    return {
      countries: []
    }
  },
  methods: {
    setInput(input) {
      this.$emit('input', input)
    }
  },
  async mounted() {
    this.countries = await fetchCountries()
  }
}
</script>
