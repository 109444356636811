<template>
  <div class="card">
    <div class="card-content">
      <div class="content">
        <v-form
          ref="editProfile"
          :initialValue="person"
          :on-submit="submitProfile"
          :on-submit-success="successSubmitProfile"
          :on-error="errorSubmitProfile"
        >
          <template v-slot="{ form }">
            <div class="columns">
              <div class="column is-one-third has-text-right">
                <strong>{{ $t('personEditProfile.first_name') }}</strong>
              </div>
              <div class="column">
                <b-field
                  id="first-name-input"
                  :message="form.errors.first_name"
                  :type="form.types.first_name"
                >
                  <b-input v-model="form.values.first_name"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-one-third has-text-right">
                <strong>{{ $t('personEditProfile.last_name') }}</strong>
              </div>
              <div class="column">
                <b-field
                  id="last-name-input"
                  :message="form.errors.last_name"
                  :type="form.types.last_name"
                >
                  <b-input v-model="form.values.last_name"></b-input>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column is-one-third has-text-right">
                <strong>{{ $t('personCard.email') }}</strong>
              </div>
              <div class="column">
                {{ person.email }}
              </div>
            </div>

            <div class="columns">
              <div class="column is-one-third has-text-right">
                <strong> {{ $t('personCard.nationality') }} </strong>
              </div>
              <div class="column">
                <b-field
                  id="nationality-input"
                  :message="form.errors.nationality"
                  :type="form.types.nationality"
                >
                  <country-selector v-model="form.values.nationality" />
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column is-one-third has-text-right">
                <strong>{{ $t('personCard.idNumber') }}</strong>
              </div>
              <div class="column">
                <b-field
                  id="id_number-input"
                  :message="form.errors.id_number"
                  :type="form.types.id_number"
                >
                  <b-input v-model="form.values.id_number"></b-input>
                </b-field>
              </div>
            </div>
            <div class="buttons is-right">
              <b-button @click="$emit('closeEditProfile')">{{
                $t('personEditProfile.cancelButton')
              }}</b-button>
              <b-button native-type="submit" type="is-azul-imfd" :disabled="!!form.erorrs"
                >{{ $t('personEditProfile.saveButton') }}
              </b-button>
            </div>
          </template>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import VForm from '@/components/VForm'
import CountrySelector from './CountrySelector.vue'
import { patchProfile } from '@/requests/people'

export default {
  name: 'editProfile',
  props: {
    person: {
      type: Object,
      required: true
    }
  },
  components: {
    VForm,
    CountrySelector
  },
  data() {
    return {
      countries: []
    }
  },
  methods: {
    async submitProfile(form) {
      const editedProfile = await patchProfile(form)
      this.$store.commit('auth/refreshProfile', editedProfile)
    },
    successSubmitProfile() {
      this.$buefy.snackbar.open({
        message: this.$t('personEditProfile.editedSuccess'),
        type: 'is-success',
        position: 'is-top'
      })
      this.$emit('closeEditProfile')
    },
    errorSubmitProfile(error) {
      if (Object.prototype.hasOwnProperty.call(error, 'report')) {
        this.$buefy.toast.open({
          message: error.report.detail,
          type: 'is-danger'
        })
      }
    }
  }
}
</script>

<style></style>
